<template>
  <div :style="style"
       class="loader">
    <div class="loader__wrapper">
      <div class="loader__dots">
        <div class="loader__dot"></div>
        <div class="loader__dot"></div>
        <div class="loader__dot"></div>
        <div class="loader__dot"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    params: {
      type: Object,
      default: () => ({
        style: {
          width: '100%',
          height: '100%',
        },
      }),
    },
  },
  computed: {
    style() {
      const style = {};
      Object.keys(this.params.style)
        .forEach((key) => {
          style[key] = this.params.style[key];
        });
      return style;
    },
  },
};
</script>

<style lang="scss">
  @import '../assets/style/utilits/index';

  .loader {
    margin: 0 auto;

    &__wrapper {
      @include flex();
      align-items: center;
      justify-content: center;
      @include size(100%, 100%);
      background-color: $loader;
      border-radius: 5px;
      box-shadow: $shadow;
    }

    &__dots {
      @include position(relative);
      @include size(80px, 80px);
    }

    &__dot {
      @include position(absolute, $top: 50%);
      @include size(13px, 13px);
      border-radius: 50%;
      background-color: $green;
      animation-timing-function: cubic-bezier(0, 1, 1, 0);

      &:nth-child(1) {
        @include position(absolute, $left: 8px);
        animation: dot1 .6s infinite;
      }

      &:nth-child(2) {
        @include position(absolute, $left: 8px);
        animation: dot2 .6s infinite;
      }

      &:nth-child(3) {
        @include position(absolute, $left: 32px);
        animation: dot2 .6s infinite;
      }

      &:nth-child(4) {
        @include position(absolute, $left: 56px);
        animation: dot3 .6s infinite;
      }
    }
  }

  @keyframes dot1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes dot3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }

  @keyframes dot2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
</style>
